<template lang="pug">
  .ModalContainerImage#ModalContainerImage
    #Overlay.Overlay.bg-black.inset-0.fixed.z-40.transition.flex.justify-center.overflow-auto.max-h-screen
      #Modal.Modal.ModalStyles.p-3.w-full.relative.z-50.transition.duration-500
        header(v-if="!hideHeader").w-full.p-1.flex.justify-end
          button(@click="() => handleCloseModal()").border-none
            .material-icons.text-white close
        .contentModal
          slot
</template>

<script>
export default {
  name: "ModalImage",
  props: {
    closeModal: Function,
    hideHeader: Boolean,
    cancelCloseOverlay: Boolean,
  },
  created() {
    this.$emit("close-button", this.handleCloseModal);
  },
  methods: {
    handleCloseModal(overlay) {
      const $Modal = this.$el.querySelector(".ModalContainerImage #Modal");
      $Modal.classList.remove("Modal");

      const $Overlay = this.$el.querySelector(".ModalContainerImage #Overlay");
      $Overlay.classList.remove("Overlay");

      if (!overlay) {
        this.closeFunction($Modal, $Overlay);
      } else {
        if (!this.cancelCloseOverlay) {
          this.closeFunction($Modal, $Overlay);
        }
      }
    },
    closeFunction($Modal, $Overlay) {
      const timer1 = setTimeout(() => {
        $Modal.classList.add("ModalStatic");
        $Overlay.classList.add("OverlayStatic");
        clearTimeout(timer1);
      }, 200);

      const timer = setTimeout(() => {
        this.closeModal();
        clearTimeout(timer);
      }, 600);
    },
  },
}
</script>

<style scoped>
.Overlay {
  opacity: 0;
  transition: 1s all;
  animation: forwards 0.5s ease-out onlyFadeImg;
  z-index: 9999999999;
}

.OverlayStatic {
  opacity: 1;
  transition: 1s all;
  animation-delay: 2s;
  animation: forwards 0.5s ease-out onlyFade alternate-reverse;
}

.Modal {
  transform: translateY(10000px);
  animation: forwards 0.6s ease-out fadeTop alternate;
  z-index: 99999999999;
}

.ModalStatic {
  animation: forwards 0.6s ease-out fadeTop alternate-reverse;
}

.ModalStyles {
  border-radius: 25px 25px 0 0;
  top: 0px;
}
</style>